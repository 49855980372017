<template>
  <v-list-item class="app-config px-md-8 px-4" v-if="setting">
    <v-list-item-action class="d-none d-md-block">
      <code class="pa-2" style="color: green">{{ setting.profile }}</code>
    </v-list-item-action>

    <v-list-item-content :class="setting.disabled ? 'setting-disabled' : ''">
      <v-list-item-subtitle class="settings-title">
        <span> {{ setting.name.split(".").join(" ▸ ") }} </span>
        <code class="ml-1 d-md-none" style="color: green"> {{ setting.profile }} </code>
        <code class="ml-1" style="color: grey"> {{ setting.label }} </code>
      </v-list-item-subtitle>

      <v-list-item-title v-if="!editing">
        <div class="px-3 py-2 settings-value">{{ setting.value }}</div>
      </v-list-item-title>

      <v-list-item-title v-if="editing">
        <v-textarea
          v-model="setting.value"
          :rules="valueRules"
          outlined
          hide-details
          rows="1"
          auto-grow
          dense
          autocomplete="off"
          class="settings-value"
        />
      </v-list-item-title>
    </v-list-item-content>

    <v-list-item-action class="ml-2 mr-0" v-if="!setting.disabled && editing">
      <v-btn fab small color="success" class="ma-0 mt-3" :loading="loading" @click="saveValue">
        <v-icon>mdi-check</v-icon>
      </v-btn>
    </v-list-item-action>

    <v-list-item-action class="ml-2 mr-0" v-if="!setting.disabled && !editing && canSave">
      <v-btn
        fab
        small
        outlined
        elevation="1"
        color="#39f"
        class="ma-0 mt-3"
        :loading="loading"
        @click="startEditing"
      >
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
    </v-list-item-action>

    <v-list-item-action class="ml-1 mr-0" v-if="!setting.disabled && !editing && canDelete">
      <v-btn
        fab
        small
        outlined
        elevation="1"
        color="red"
        class="ma-0 mt-3"
        :loading="loading"
        @click="deleteConfig"
      >
        <v-icon>mdi-trash-can-outline</v-icon>
      </v-btn>
    </v-list-item-action>

    <v-list-item-action class="ml-1 mr-0" v-if="setting.disabled && canSave">
      <v-btn
        fab
        small
        outlined
        elevation="1"
        color="green"
        class="ma-0 mt-3"
        :loading="loading"
        @click="saveValue"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
export default {
  name: "ApplicationSettingItem",
  props: {
    config: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    setting: null,
    loading: false,
    editing: false,
  }),
  computed: {
    valueRules() {
      return [(v) => typeof v !== "undefined" || "Value field is required"];
    },
    configUrl() {
      return [
        "",
        "api",
        "config",
        this.setting.application,
        this.setting.profile,
        this.setting.label,
        this.setting.name,
      ].join("/");
    },
    canSave() {
      return this.verifyPrivilege(this.configUrl, "PUT");
    },
    canDelete() {
      return this.verifyPrivilege(this.configUrl, "DELETE");
    },
  },
  watch: {
    config() {
      if (!this.editing) {
        this.setting = { ...this.config };
      }
    },
  },
  mounted() {
    this.setting = { ...this.config };
  },
  methods: {
    async fetchLatest() {
      const response = await this.$axios.get(this.configUrl);
      if (response.data.code !== 0) {
        throw new Error(response.data.message);
      }
      this.setting.value = response.data.data;
    },
    async saveValue() {
      try {
        if (this.setting.value === this.config.value) {
          // Value unchanged, no need to send update on server
          return;
        }
        this.loading = true;
        const response = await this.$axios.put(this.configUrl, this.setting.value);
        if (response.data.code !== 0) {
          throw new Error(response.data.message);
        }
        this.setting.disabled = false;
        this.$emit("change", this.setting);
      } catch (err) {
        this.$iziToast.showError(err);
      } finally {
        this.loading = false;
        this.editing = false;
      }
    },
    async startEditing() {
      try {
        this.loading = true;
        await this.fetchLatest();
        this.editing = true;
      } catch (err) {
        this.$iziToast.showError(err);
      } finally {
        this.loading = false;
      }
    },
    async deleteConfig() {
      try {
        this.loading = true;
        const response = await this.$axios.delete(this.configUrl);
        if (response.data.code !== 0) {
          throw new Error(response.data.message);
        }
        this.setting.disabled = true;
        this.$emit("change", this.setting);
      } catch (err) {
        this.$iziToast.showError(err);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.app-config {
  .setting-disabled {
    cursor: not-allowed;

    .settings-title,
    .settings-value,
    code {
      display: inline-block;
      color: rgba(0, 0, 0, 0.5) !important;
    }
  }

  .settings-title {
    font-family: "Roboto Slab";
    color: teal;
    white-space: pre-wrap;
    word-wrap: break-word;
    letter-spacing: 0.25px;
  }

  .settings-value {
    font-family: monospace;
    color: black;
    white-space: pre-wrap;
    word-wrap: break-word;
  }
}
</style>
